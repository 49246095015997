import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { GoogleMapsAPIProvider } from '@pledge-earth/web-components';
import type { ReactNode } from 'react';
import { GlobalToastRegion } from '@pledge-earth/product-language';

import { ReduxSagaProvider } from '../store/provider';
import { reducer as settings } from '../store/settings/reducers';
import { Localization } from '../localization';

const reducer = combineReducers({
  settings,
});

const store = configureStore({
  reducer,
});

export function ProviderImpact({ children }: { children: ReactNode }) {
  return (
    <ReduxSagaProvider store={store}>
      <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
        <Localization>
          {children}
          <GlobalToastRegion />
        </Localization>
      </GoogleMapsAPIProvider>
    </ReduxSagaProvider>
  );
}
